






















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Ticket16 from '@carbon/icons-vue/es/ticket/16'
import isEmpty from 'lodash/isEmpty'

import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import GenericModalForm from '@/partials/modals/generic/form.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import EventUserDropdown from '@/partials/forms/Events/EventUserDropdown.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    EventUserDropdown,
    PasswordConfirmField,
    GenericModalDelete,
    GenericModalForm,
    TrashCan16,
    Ticket16,
    Edit16
  }
})
export default class StudyReviewTypesTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  reviewTypes = [] as any
  filteredData = [] as any
  deleteModalIsOpened = false
  selectedReviewType = {
    id: '',
    title: ''
  }

  created () {
    this.fetch()
  }

  fetch () {
    axios.get(`/event/${this.eventId}/study-review-type`)
      .then(response => {
        this.reviewTypes = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'O Tipo de Avaliação foi removido com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  get deleteRequestBody () {
    return { eventUsers: [isEmpty(this.selectedReviewType) ? '' : this.selectedReviewType.id] }
  }

  get deleteUrl () {
    return isEmpty(this.selectedReviewType) ? '' : `/event/${this.eventId}/study-review-type/${this.selectedReviewType.id}`
  }
}
