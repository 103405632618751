






import { Component, Vue } from 'vue-property-decorator'

import StudyReviewTypesTable from '@/partials/tables/Events/StudyReviewTypes/index.vue'

@Component({
  components: {
    StudyReviewTypesTable
  }
})
export default class EventStudyReviewTypes extends Vue {

}
